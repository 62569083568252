/**
 * Converts a single item reference into an array of that item type.
 * This utility is particularly convenient for interacting with the Combobox.vue when single select mode is enabled (i.e., when `isMultiple` is false).
 */
export const useItemAsArray = function <T>(item: Ref<T | undefined>) {
  const itemAsArray = computed<T[]>({
    get: () => (item.value ? [item.value] : []),
    set: (newValue) => {
      item.value = newValue[0];
    },
  });

  return itemAsArray;
};
